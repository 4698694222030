import axios from "axios";

export default function requestUpdateSlider(endpoint){

    const { id, data } = endpoint;

    const config = {
        headers: {
            "x-token": localStorage.getItem('token'),
            "Content-Type": "application/json"
        }        
    };

    const lang = localStorage.getItem('i18nextLng');

    return axios.put(process.env.REACT_APP_API_URL+'/'+lang+'/slider/'+id, data, config);

}