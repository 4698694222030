import { createSlice } from '@reduxjs/toolkit';

export const propertyvalSlice = createSlice({
  name: 'propertyval',
  initialState: {
    new: 0,
    open: false,
    id: 0,
    type: null,
    pagename: null,
    pageid: null,
    value: null
  },
  reducers: {
    getPostlistvalue() {},
    setPostlistvalue(state, action) {
      const property = action.payload;
      state.value = property;
    },
    setIdValue(state, action){
      const { id } = action.payload;
      state.id = id;
    },
    createPostlistvalue() {},
    addPostlistvalue(state, action) {
      state.new += 1;
      state.open = false;
      return state;
    },
    openPanel(state, action) {
      const { open, pagename, type, pageid } = action.payload;
      state.open = open;
      state.pagename = pagename;
      state.pageid = pageid;
      state.type = type;
    }, 
    purgePostValue(state) {
      state.value = null;
    },  
    purgePostValueState(state) {      
      state.new = 0;
      state.open = false;
      state.id = 0;
      state.type = null;
      state.pagename = null;
      state.pageid = null;
      state.value = null;
      return state;
    },     
    clearState: (state, action) => {
      state.id = 0;
      return state;
    }
  }
})

export const { getPostlistvalue, setPostlistvalue, openPanel, createPostlistvalue, addPostlistvalue, clearState, setIdValue, purgePostValueState, purgePostValue } = propertyvalSlice.actions;

export default propertyvalSlice.reducer;