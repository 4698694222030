import { call, put } from "redux-saga/effects";
import { setPropertyvalue } from "../../slices/property";
import requestgetPropertyvalue from "../request/getpropertyvalue";
import errors from "src/redux/saga/errors";

export function* handleGetPropertyvalue(action) {
    try {
        const { payload } = action;        
        const response = yield call(requestgetPropertyvalue, payload);
        const { data } = response;
        yield put(setPropertyvalue(data));
    } catch (error){ 
        console.log(error);
        errors(error);
    }
}