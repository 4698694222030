import { call, put } from "redux-saga/effects";
import { setProductitem } from "../../slices/product";
import requestputProductitem from "../request/updateproductitem";
import errors from "src/redux/saga/errors";

export function* handleUpdateProductitem(action) {
    try {
        const { payload } = action;
        const response = yield call(requestputProductitem, payload);
        const { data } = response;
        yield put(setProductitem(data));
    } catch (error){ 
        console.log(error);
        errors(error);
    }
}