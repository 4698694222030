import axios from 'axios';
import { createSlice } from '@reduxjs/toolkit';
import { getSingle } from 'src/redux/slices/single';


// ----------------------------------------------------------------------

const initialState = {
    isLoading: false,
    dot: [],
    idDot: 0,
    openDotConfig: false,
    error: false,
    dotvalues: []
};

const slice = createSlice({
    name: 'dots',
    initialState,
    reducers: {

    // START LOADING
    startLoading(state) {
        state.isLoading = true;
    },

    openDot(state, action) {
        state.openDotConfig = action.payload;
    },

    // GET DATA
    dataSuccess(state, action) {
        state.isLoading = false;
        state.menu = action.payload.data;
    },

    dataDotFlush(state, action) {
        state.dot = [];
    },

    // GET DOT
    dotSuccess(state, action) {
        state.isLoading = false;
        state.dot = action.payload.dot;
    },

    // GET DOT
    idDot(state, action) {
        state.idDot = action.payload;
    },

    // ERRORS RETRIVED
    hasError(state) {
        state.isLoading = false;
        state.error = true;
    },

    updateDotvalue(state, action) {
        state.dotvalues = action.payload
    }
    }
});

// Reducer
export default slice.reducer;

// Actions
export const { dataSuccess, hasError, purgeData, updateDotvalue, openDot, idDot, dataDotFlush } = slice.actions;

// ----------------------------------------------------------------------

export function createDot(element) {
    return async (dispatch) => {
        const config = {
            headers: {
                "x-token": localStorage.getItem('token'),
                "Content-Type": "application/json"
            }
        };
        try {
            const lang = localStorage.getItem('i18nextLng');
            const response = await axios.post(process.env.REACT_APP_API_URL + '/' + lang + '/dotsposition/create', element, config);
            const { data } = response;

            dispatch(slice.actions.dataSuccess({ data: data }));
            dispatch(getSingle({ title: element.model, id: element.id }));
        } catch (err) {
            dispatch(slice.actions.hasError());
        }
    };
}

export function getDot(id) {
    return async (dispatch) => {
        const config = {
            headers: {
                "x-token": localStorage.getItem('token'),
                "Content-Type": "application/json"
            }
        };
        try {
            const lang = localStorage.getItem('i18nextLng');
            const response = await axios.get(process.env.REACT_APP_API_URL + '/' + lang + '/dotsposition/' + id, config);
            const { data } = response;
            dispatch(slice.actions.dotSuccess({ dot: data }));
        } catch (err) {
            dispatch(slice.actions.hasError());
        }
    };
}

export function updateDot(id, dot, model, postid) {
    return async (dispatch) => {
        const config = {
            headers: {
                "x-token": localStorage.getItem('token'),
                "Content-Type": "application/json"
            }
        };
        try {
            const lang = localStorage.getItem('i18nextLng');
            const response = await axios.put(process.env.REACT_APP_API_URL + '/' + lang + '/dotsposition/' + id, dot, config);
            const { data } = response;
            dispatch(getSingle({ title: model, id: postid }));
        } catch (err) {
            dispatch(slice.actions.hasError());
        }
    };
}

export function deleteDot(element) {
    return async (dispatch) => {
        const config = {
            headers: {
                "x-token": localStorage.getItem('token'),
                "Content-Type": "application/json"
            }
        };
        try {
            const lang = localStorage.getItem('i18nextLng');
            const response = await axios.delete(process.env.REACT_APP_API_URL + '/' + lang + '/dotsposition/delete', {
                headers: {
                    "x-token": localStorage.getItem('token'),
                    "Content-Type": "application/json"
                },
                data: element
            });
            const { data } = response;

            dispatch(slice.actions.dataSuccess({ data: data }));
            dispatch(getSingle({ title: element.model, id: element.postid }));
        } catch (err) {
            dispatch(slice.actions.hasError());
        }
    };
}