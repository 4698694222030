import { createSlice } from '@reduxjs/toolkit';

export const field = createSlice({
  name: 'fieldrepeater',
  initialState: {
    openfieldvisual: null,
    openfield: null,    
    field: null,
    entity: null,
    entityid: null,
    name: null,
    id: null
  },
  reducers: {
    createFieldrepeater() {},
    getFieldrepeater() {},
    updateFieldrepeater() {},
    setIdFieldrepeater(state, action) {
      const id = action.payload;
      state.id = id;
    },
    setFieldrepeater(state, action) {      
      const field = action.payload;
      state.field = field;
      state.id = field[0].value;
    },      
    openFieldrepeater(state, action) {
      const { openfield } = action.payload;
      state.openfield = openfield;
    },
    openFieldVisualBlock(state, action) {
      const { openfieldvisual } = action.payload;
      state.openfieldvisual = openfieldvisual;
    },    
    purgeFieldrepeater(state, action) {
      state.openfield = false;
      state.openfieldvisual = false;
      state.entity = null;
      state.field = null;
      state.entityid = null;
      state.name = null;
      state.id = null;      
    }
  }
})

export const { updateFieldrepeater, getFieldrepeater, setFieldrepeater, createFieldrepeater, purgeFieldrepeater, setIdFieldrepeater, openFieldrepeater, openFieldVisualBlock } = field.actions;

export default field.reducer;