import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';

export const visual = createSlice({
  name: 'visual',
  initialState: {
    openBlockSetup: false,
    block: null,
    entity: null,
    entityid: null,
    name: null,
    id: null,
    index: null,
    isSuccess: false,
    isError: false,
  },
  reducers: {
    addBlock() {},
    saveBlock() {},
    getBlock() {},
    setBlock() {      
      // return state;
    },
    successSaveBlock(state, action) {
      state.isSuccess = true
    },
    setIs(state, action) {
      state.isError = false;
      state.isSuccess = false;
    },
    openBlock(state, action) {
      const { openBlockSetup, block, entity, entityid, name, id, index } = action.payload;
      state.block = block;
      state.openBlockSetup = openBlockSetup;
      state.entity = entity;
      state.entityid = entityid;
      state.name = name;
      state.id = id;
      state.index = index
    },
    closeBlock(state, action) {
      state.block = null;
      state.openBlockSetup = false;
      state.entity = null;
      state.entityid = null;
      state.name = null;
      state.id = null;
      state.index = null
    }
  }
})

// Reducer
export default visual.reducer;

// Actions
export const { 
  saveBlock, 
  openBlock, 
  getBlock, 
  setBlock, 
  addBlock, 
  closeBlock, 
  editBlock, 
  openVisualSetup, 
  successSaveBlock,
  setIs 
} = visual.actions;

// ----------------------------------------------------------------------

// export function addBlocks() {
//   return async (dispatch) => {
//       dispatch(visual.actions.startLoading());
//       const config = {
//           headers: {
//               "x-token": localStorage.getItem('token'),
//               "Content-Type": "application/json"
//           }        
//       };
//       const locale = localStorage.getItem('i18nextLng');
//       try {
//           const response = await axios.getput(process.env.REACT_APP_API_URL+'/'+locale+'/'+entity+'/'+entityid+'/visual/blocks_visual_blocks/'+id, config);
//           const { data } = response;

//           dispatch(visual.actions.dataSuccess({ }));
//       } catch(err) {
//           dispatch(visual.actions.hasError());
//       }
//   };
// }