import { call, put } from "redux-saga/effects";
import { setListing, purgeListingState } from "../../slices/listing";
import requestgetListing from "../request/listing";
import errors from "src/redux/saga/errors";

export function* handleListing(action) {
    try {
        yield put(purgeListingState());
        const { payload } = action;
        const response = yield call(requestgetListing, payload);
        const { data } = response;        
        yield put(setListing(data));
    } catch (error){        
        console.log(error);
        errors(error);
    }
}