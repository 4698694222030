import axios from "axios";

export default function requestGetFormfieldoptions(endpoint){

    const { id } = endpoint;

    const config = {
        headers: {
            "x-token": localStorage.getItem('token'),
            "Content-Type": "application/json"
        }        
    };

    return axios.get(process.env.REACT_APP_API_URL+'/form/field/options/'+id, config);

}