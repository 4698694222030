import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';

export const formcsvfield = createSlice({
    name: 'formcsvfield',
    initialState: {
        setting: [],
        smtp: [],
        csv: '',
        csvfields: [],
        authapi: [],
        mailchimp: [],
        mailchimpfields: [],
        isError: false,
        isSuccess: false
    },
    reducers: {
        hasError(state, action) {
            state.isError = true;
        },
        IsSuccess(state, action) {
            state.isSuccess = true;
        },
        addFormCsvField(state, action) {

        },
        updateFormCsvField(state, action) {

        }        
    }
})

// Reducer
export default formcsvfield.reducer;

// Actions
export const {
    addFormCsvField,
    updateFormCsvField
} = formcsvfield.actions;

// ----------------------------------------------------------------------

// export function addFormCsvField(id) {
//     return async (dispatch) => {
//         const config = {
//             headers: {
//                 "x-token": localStorage.getItem('token'),
//                 "Content-Type": "application/json"
//             }
//         };
//         try {
//             const response = await axios.post(process.env.REACT_APP_API_URL + '/form/csv/field/' + id, config);
//             const { data } = response;
//             console.log(data);

//         } catch (err) {
//             console.log(err);
//         }
//     };
// }

// export function updateFormCsvField({ id, value }) {
//     return async (dispatch) => {
//         const config = {
//             headers: {
//                 "x-token": localStorage.getItem('token'),
//                 "Content-Type": "application/json"
//             }
//         };
//         const post = {
//             name: value
//         };
//         try {
//             const response = await axios.put(process.env.REACT_APP_API_URL + '/form/setting/csv/' + id, post, config);
//             const { data } = response;
//             console.log(data);

//         } catch (err) {
//             console.log(err);
//         }
//     };
// }