import { call, put } from "redux-saga/effects";
import { setPost, purgePost } from "../../slices/single";
import requestCopyColumns from "../request/copycolumns";
import errors from "src/redux/saga/errors";
import { getSingle, purgeSingleState } from 'src/redux/slices/single';

export function* handleCopyColumns(action) {
    try {
        const { payload } = action;
        const response = yield call(requestCopyColumns, payload);
        const { data } = response;
        yield put(purgePost());
        yield put(setPost(data));
    } catch (error){ 
        console.log(error);
        errors(error);
    }
}