import axios from 'axios';
import { createSlice } from '@reduxjs/toolkit';
import IconMenu from "src/utils/iconMenu";


// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  menu: null,
  error: false
};

const slice = createSlice({
  name: 'menu',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // GET DATA MENU
    dataSuccess(state, action) {
      state.isLoading = false;
      state.menu = action.payload.data;
    },

    // ERRORS RETRIVED
    hasError(state) {
        state.isLoading = false;
        state.error = true;
    },
  }
});

// Reducer
export default slice.reducer;

// Actions
export const { dataSuccess, hasError, purgeData } = slice.actions;

// ----------------------------------------------------------------------

export function menuRequest() {
    return async (dispatch) => {
        dispatch(slice.actions.startLoading());
        const config = {
            headers: {
                "x-token": localStorage.getItem('token'),
                "Content-Type": "application/json"
            }        
        };
        try {
            const response = await axios.get(process.env.REACT_APP_API_URL+'/menu', config);
            const { data } = response;
            const MenuLinks = IconMenu(data);

            dispatch(slice.actions.dataSuccess({ data: MenuLinks }));
        } catch(err) {
            dispatch(slice.actions.hasError());
        }
    };
}