import { call, put } from "redux-saga/effects";
import requestCreateFormfieldoptions from "../request/createformfieldoption";
import errors from "src/redux/saga/errors";
import { feedOption } from "../../slices/formfield";

export function* handleCreateFormfieldOptions(action) {
    try {
        const { payload } = action;
        const response = yield call(requestCreateFormfieldoptions, payload);
        const { data } = response;
        yield put(feedOption({data}));
    } catch (error){ 
        console.log(error);
        errors(error);
    }
}