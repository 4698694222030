import history from "src/routes/history";
import { clearLog } from "src/redux/slices/login";
import { setError } from "src/redux/slices/single";
import { store } from 'src/redux/store';

export default function errors(error) {

    // const state = store.getState();
    console.log('status', error.response.status);    
    let txt = false;

    if(error.response.status === 401){
        store.dispatch(clearLog());
        localStorage.removeItem('email');
        localStorage.removeItem('username');
        localStorage.removeItem('token');
        localStorage.removeItem('role');        
        history.push('/login');
    } else if(error.response.status === 404 || error.response.status === 405) {
        history.push('/404');
    } else  if(error.response.status === 400){
        console.log('data', error.response.data);
        txt = error.response.data;        
    }    
    store.dispatch(setError(txt));

}