import axios from "axios";

export default function requestgetProductitem(endpoint){

    const { model, id } = endpoint;

    const lang = localStorage.getItem('i18nextLng');

    const config = {
        headers: {
            "x-token": localStorage.getItem('token'),
            "Content-Type": "application/json"
        }        
    };

    const data = {
        id: id
    };

    return axios.post(process.env.REACT_APP_API_URL+'/'+lang+'/'+model+'/item/create', data, config);

}