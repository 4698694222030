import { call, put } from "redux-saga/effects";
import { setField } from "../../slices/formfield";
import requestCreateFormfield from "../request/createformfield";
import errors from "src/redux/saga/errors";
import { getSingle, purgeSingleState } from 'src/redux/slices/single';

export function* handleCreateFormfield(action) {
    try {
        const { payload } = action;
        const response = yield call(requestCreateFormfield, payload);
        const { data } = response;
        yield put(setField(data));
        // yield put(purgeSingleState());
        yield put(getSingle({title: payload.entity, id: payload.id}));
    } catch (error){ 
        console.log(error);
        errors(error);
    }
}