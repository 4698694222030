import { call, put } from "redux-saga/effects";
import requestGetFormfieldoptions from "../request/getoptions";
import errors from "src/redux/saga/errors";
import { openOptions } from "../../slices/formfield";

export function* handleGetFormfieldOptions(action) {
    try {
        const { payload } = action;
        const response = yield call(requestGetFormfieldoptions, payload);
        const { data } = response;
        yield put(openOptions({openOptionsSetup: payload.openOptionsSetup, options: data.items, typelist: data.typelist, type: data.type, id: data.id}));
        // yield put(getSingle({title: payload.model, id: payload.id}));
    } catch (error){ 
        console.log(error);
        errors(error);
    }
}