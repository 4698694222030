import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';

export const formsetting = createSlice({
    name: 'formsetting',
    initialState: {
        setting: [],
        smtp: [],
        csv: '',
        csvfields: [],
        authapi: [],
        mailchimp: [],
        mailchimpfields: [],
        isError: false,
        isSuccess: false
    },
    reducers: {
        addSetting(state, action) {
            const { data } = action.payload;
            state.setting = data;
        },
        addSettingSmtp(state, action) {
            const { data } = action.payload;
            state.smtp = data;
        },
        addSettingAuth(state, action) {
            const { data } = action.payload;
            state.authapi = data;
        },
        hasError(state, action) {
            state.isError = true;
        },
        IsSuccess(state, action) {
            state.isSuccess = true;
        },
        setIsFalse(state, action) {
            state.isError = false;
            state.isSuccess = false;
        },
        addSettingCsv(state, action) {
            const { csv, fields } = action.payload;
            state.csvfields = fields;
            state.csv = csv;
        },
        addSettingMailchimp(state, action) {
            const { data } = action.payload;
            state.mailchimp = data;
        },
        addSettingMailchimpField(state, action) {
            const { data } = action.payload;
            state.mailchimpfields = data;
        }
    }
})

// Reducer
export default formsetting.reducer;

// Actions
export const {
    addSetting,
    addSettingSmtp,
    addSettingAuth,
    addSettingCsv,
    setIsFalse
} = formsetting.actions;

// ----------------------------------------------------------------------

export function getSetting() {
    return async (dispatch) => {
        const config = {
            headers: {
                "x-token": localStorage.getItem('token'),
                "Content-Type": "application/json"
            }
        };
        try {
            const response = await axios.get(process.env.REACT_APP_API_URL + '/form/setting', config);
            const { data } = response;

            dispatch(formsetting.actions.addSetting({ data }));
        } catch (err) {
            dispatch(formsetting.actions.hasError());
            console.log(err);
        }
    };
}

export function getSettingSmtp() {
    return async (dispatch) => {
        const config = {
            headers: {
                "x-token": localStorage.getItem('token'),
                "Content-Type": "application/json"
            }
        };
        try {
            const response = await axios.get(process.env.REACT_APP_API_URL + '/form/setting/smtp', config);
            const { data } = response;

            dispatch(formsetting.actions.addSettingSmtp({ data: data.items }));
        } catch (err) {
            dispatch(formsetting.actions.hasError());
            console.log(err);
        }
    };
}

export function getSettingAuth() {
    return async (dispatch) => {
        const config = {
            headers: {
                "x-token": localStorage.getItem('token'),
                "Content-Type": "application/json"
            }
        };
        try {
            const response = await axios.get(process.env.REACT_APP_API_URL + '/form/setting/auth', config);
            const { data } = response;

            dispatch(formsetting.actions.addSettingAuth({ data: data.items }));
        } catch (err) {
            dispatch(formsetting.actions.hasError());
            console.log(err);
        }
    };
}

export function getSettingMailchimp() {
    return async (dispatch) => {
        const config = {
            headers: {
                "x-token": localStorage.getItem('token'),
                "Content-Type": "application/json"
            }
        };
        try {
            const response = await axios.get(process.env.REACT_APP_API_URL + '/form/setting/mailchimp', config);
            const { data } = response;

            const responsefield = await axios.get(process.env.REACT_APP_API_URL + '/form/setting/mailchimp/field', config);
            const fields = responsefield;

            dispatch(formsetting.actions.addSettingMailchimp({ data: data.items }));
            dispatch(formsetting.actions.addSettingMailchimpField({ data: fields.data }));
        } catch (err) {
            dispatch(formsetting.actions.hasError());
            console.log(err);
        }
    };
}

export function getSettingMailchimpField() {
    return async (dispatch) => {
        const config = {
            headers: {
                "x-token": localStorage.getItem('token'),
                "Content-Type": "application/json"
            }
        };
        try {
            const response = await axios.get(process.env.REACT_APP_API_URL + '/form/setting/mailchimp/field', config);
            const { data } = response;

            dispatch(formsetting.actions.addSettingMailchimpField({ data }));
        } catch (err) {
            dispatch(formsetting.actions.hasError());
            console.log(err);
        }
    };
}

export function getSettingCsv() {
    return async (dispatch) => {
        const config = {
            headers: {
                "x-token": localStorage.getItem('token'),
                "Content-Type": "application/json"
            }
        };
        try {
            const response = await axios.get(process.env.REACT_APP_API_URL + '/form/setting/csv', config);
            const { data } = response;
            const { csv, fields } = data;

            dispatch(formsetting.actions.addSettingCsv({ csv, fields }));
        } catch (err) {
            dispatch(formsetting.actions.hasError());
            console.log(err);
        }
    };
}

export function addSettingCsvField() {
    return async (dispatch) => {
        const config = {
            headers: {
                "x-token": localStorage.getItem('token'),
                "Content-Type": "application/json"
            }
        };
        try {
            const response = await axios.post(process.env.REACT_APP_API_URL + '/form/setting/csv/create', {}, config);
            const { data } = response;
            const { csv, fields } = data;

            dispatch(formsetting.actions.addSettingCsv({ csv, fields }));
        } catch (err) {
            dispatch(formsetting.actions.hasError());
            console.log(err);
        }
    };
}

export function addSettingMailchimpField({id}) {
    return async (dispatch) => {
        const config = {
            headers: {
                "x-token": localStorage.getItem('token'),
                "Content-Type": "application/json"
            }
        };
        try {
            const response = await axios.post(process.env.REACT_APP_API_URL + '/form/setting/mailchimp/field/create/'+id, {}, config);
            const { data } = response;

            dispatch(formsetting.actions.addSettingMailchimpField({ data }));
        } catch (err) {
            dispatch(formsetting.actions.hasError());
            console.log(err);
        }
    };
}

export function updateSettingSmtp(post) {
    return async (dispatch) => {
        const config = {
            headers: {
                "x-token": localStorage.getItem('token'),
                "Content-Type": "application/json"
            }
        };
        try {
            const response = await axios.put(process.env.REACT_APP_API_URL + '/form/setting/smtp', post, config);
            const { data } = response;

            dispatch(formsetting.actions.IsSuccess());
            dispatch(formsetting.actions.addSettingSmtp({ data: data.items }));
        } catch (err) {
            dispatch(formsetting.actions.hasError());
            console.log(err);
        }
    };
}

export function updateSettingAuth(post) {
    return async (dispatch) => {
        const config = {
            headers: {
                "x-token": localStorage.getItem('token'),
                "Content-Type": "application/json"
            }
        };
        try {
            const response = await axios.put(process.env.REACT_APP_API_URL + '/form/setting/auth', post, config);
            const { data } = response;

            dispatch(formsetting.actions.IsSuccess());
            dispatch(formsetting.actions.addSettingAuth({ data: data.items }));
        } catch (err) {
            dispatch(formsetting.actions.hasError());
            console.log(err);
        }
    };
}

export function updateSettingMailchimp(post) {
    return async (dispatch) => {
        const config = {
            headers: {
                "x-token": localStorage.getItem('token'),
                "Content-Type": "application/json"
            }
        };
        try {
            const response = await axios.put(process.env.REACT_APP_API_URL + '/form/setting/mailchimp', post, config);
            const { data } = response;

            dispatch(formsetting.actions.IsSuccess());
            dispatch(formsetting.actions.addSettingMailchimp({ data: data.items }));
        } catch (err) {
            dispatch(formsetting.actions.hasError());
            console.log(err);
        }
    };
}

export function updateSettingMailchimpField({ id, value, field, email, tag }) {
    return async (dispatch) => {
        const config = {
            headers: {
                "x-token": localStorage.getItem('token'),
                "Content-Type": "application/json"
            }
        };
        const post = {
            value: value,
            field: field,
            email: email,
            tag: tag
        };
        try {
            const response = await axios.put(process.env.REACT_APP_API_URL + '/form/setting/mailchimp/field/' + id, post, config);
            const { data } = response;

            dispatch(formsetting.actions.addSettingMailchimpField({ data }));
        } catch (err) {
            dispatch(formsetting.actions.hasError());
            console.log(err);
        }
    };
}

export function updateSettingCsv({ value }) {
    return async (dispatch) => {
        const config = {
            headers: {
                "x-token": localStorage.getItem('token'),
                "Content-Type": "application/json"
            }
        };
        const post = {
            name: value
        };
        try {
            const response = await axios.put(process.env.REACT_APP_API_URL + '/form/setting/csv/', post, config);
            const { data } = response;
            const { csv, fields } = data;


            dispatch(formsetting.actions.IsSuccess());
            dispatch(formsetting.actions.addSettingCsv({ csv, fields }));
        } catch (err) {
            dispatch(formsetting.actions.hasError());
            console.log(err);
        }
    };
}

export function updateSettingCsvField({ id, value }) {
    return async (dispatch) => {
        const config = {
            headers: {
                "x-token": localStorage.getItem('token'),
                "Content-Type": "application/json"
            }
        };
        const post = {
            name: value
        };
        try {
            const response = await axios.put(process.env.REACT_APP_API_URL + '/form/setting/csv/' + id, post, config);
            const { data } = response;
            const { csv, fields } = data;


            dispatch(formsetting.actions.IsSuccess());
            dispatch(formsetting.actions.addSettingCsv({ csv, fields }));
        } catch (err) {
            dispatch(formsetting.actions.hasError());
            console.log(err);
        }
    };
}

export function deleteSettingCsvField({ id }) {
    return async (dispatch) => {
        const config = {
            headers: {
                "x-token": localStorage.getItem('token'),
                "Content-Type": "application/json"
            }
        };
        try {
            const response = await axios.delete(process.env.REACT_APP_API_URL + '/form/setting/csv/' + id, config, {});
            const { data } = response;
            const { csv, fields } = data;


            dispatch(formsetting.actions.IsSuccess());
            dispatch(formsetting.actions.addSettingCsv({ csv, fields }));
        } catch (err) {
            dispatch(formsetting.actions.hasError());
            console.log(err);
        }
    };
}

export function deleteSettingMailchimpField({ id }) {
    return async (dispatch) => {
        const config = {
            headers: {
                "x-token": localStorage.getItem('token'),
                "Content-Type": "application/json"
            }
        };
        try {
            const response = await axios.delete(process.env.REACT_APP_API_URL + '/form/setting/mailchimp/field/' + id, config, {});
            const { data } = response;

            dispatch(formsetting.actions.addSettingMailchimpField({ data }));
        } catch (err) {
            dispatch(formsetting.actions.hasError());
            console.log(err);
        }
    };
}