import { call, put } from "redux-saga/effects";
import { setFieldrepeater } from "../../slices/fieldrepeater";
import requestgetFieldrepeater from "../request/fieldrepeater";
import errors from "src/redux/saga/errors";

export function* handleFieldrepeater(action) {
    try {
        const { payload } = action;
        const response = yield call(requestgetFieldrepeater, payload);
        const { data } = response;        
        yield put(setFieldrepeater(data));
    } catch (error){ 
        console.log(error);
        errors(error);
    }
}