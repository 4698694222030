import { call, put } from "redux-saga/effects";
import { setBlock } from "../../slices/visual";
import requestCreateBlock from "../request/createblock";
import errors from "src/redux/saga/errors";
import { getSingle, purgeSingleState } from 'src/redux/slices/single';

export function* handleCreateBlock(action) {
    try {
        const { payload } = action;
        const response = yield call(requestCreateBlock, payload);
        const { data } = response;
        yield put(setBlock(data));
        // yield put(purgeSingleState());
        yield put(getSingle({title: payload.entity, id: payload.id}));
    } catch (error){ 
        console.log(error);
        errors(error);
    }
}