function urlSplitFolder(location, queryparam) {

    const arrayLocation = location.pathname.split('/');
    const folder = arrayLocation[arrayLocation.length-3];
    const mainfolder = arrayLocation[arrayLocation.length-4];
    const idpage = arrayLocation[arrayLocation.length-1];

    const queryParams = new URLSearchParams(window.location.search);
    const type = queryParams.get(queryparam);

    const obj = {
        array: arrayLocation,
        folder: folder,
        id: idpage,
        mainfolder: mainfolder,
        params: location.search,
        paramvalue: type
    };

    return obj;
}

export default urlSplitFolder;