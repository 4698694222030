import { call, put } from "redux-saga/effects";
import { getSlide, setIdSlide } from "../../slices/slider";
import requestCreateSlider from "../request/createslider";
import errors from "src/redux/saga/errors";
import { getSingle } from 'src/redux/slices/single';

export function* handleCreateSlider(action) {
    try {
        const { payload } = action;
        const response = yield call(requestCreateSlider, payload);
        const { data } = response;
        yield put(setIdSlide(data.id));
        yield put(getSlide({id: data.id}));
        // yield put(getSingle({title: payload.model, id: payload.id}));
    } catch (error){ 
        console.log(error);
        errors(error);
    }
}