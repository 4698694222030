import { combineReducers } from 'redux';
import storage from 'redux-persist/lib/storage';
import settingsReducer from './slices/settings';
import loginSlice from "./slices/login";
import listingSlice from "./slices/listing";
import singlegSlice from "./slices/single";
import langSlice from "./slices/langs";
// import propertySlice from "./slices/postlist";
// import propertyvalSlice from "./slices/postlistvalue";
import slider from "./slices/slider";
import visual from "./slices/visual";
import menu from "./slices/menu";
import filemanager from "./slices/filemanager";
import propertyvalue from "./slices/property";
import productitem from "./slices/product";
import item from "./slices/item";
import formfield from "./slices/formfield";
import formsetting from "./slices/formsetting";
import formcsv from "./slices/formcsv";
import dots from "./slices/dots";
import fieldrepeater from "./slices/fieldrepeater";

// ----------------------------------------------------------------------

const rootPersistConfig = {
  key: 'root',
  storage: storage,
  keyPrefix: 'redux-',
  whitelist: ['filemanager', 'settings', 'listing', 'single', 'slider', 'visual', 'menu', 'property', 'formfield', 'formsetting', 'formcsv', 'product', 'item', 'dots', 'fieldrepeater']
};

const rootReducer = combineReducers({
  filemanager: filemanager,
  settings: settingsReducer,
  listing: listingSlice,
  single: singlegSlice,
  // property: propertySlice,
  // propertyval: propertyvalSlice,
  slider: slider,
  visual: visual,
  login: loginSlice,
  lang: langSlice,
  property: propertyvalue,
  item: item,
  product: productitem,
  formfield: formfield,
  formsetting: formsetting,
  formcsv: formcsv,
  menu: menu,
  dots: dots,
  fieldrepeater: fieldrepeater
});

export { rootPersistConfig, rootReducer };
