import axios from 'axios';
import { createSlice } from '@reduxjs/toolkit';


// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  isSuccess: false,
  isError: false,
  isError403: false,
  breadcrumbs: [],
  files: [],
  filesSlice: []
};

const slice = createSlice({
  name: 'filemanager',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
        state.isLoading = true;
    },

    // GET DATA FILES
    dataSuccess(state, action) {
        state.isLoading = false;
        state.files = action.payload;
    },

    // GET DATA SLICE UPDATE
    dataSliceUpdate(state, action) {
        state.isLoading = false;
        state.filesSlice = action.payload;
    },

    dataOneStepback(state, action) {
        state.isLoading = false;
        state.filesSlice = action.payload;
    },    

    addBreadcrumbs(state, action) {
        const folders = state.breadcrumbs;
        folders.push(action.payload);
        const addIndex = folders.map((e, index) => {
            return {...e, index: index}
        });
        state.breadcrumbs = addIndex;
    },

    removeBreadcrumbs(state, action) {
        state.breadcrumbs = action.payload;
    },

    resetBreadcrumbs(state) {
        state.breadcrumbs = [];
    },

    addPathMove(state, action) {
        state.pathMove = action.payload;
    },

    resetPathMove(state) {
        state.pathMove = '';
    },    

    // ERRORS RETRIVED
    hasError(state) {
        state.isLoading = false;
        state.isError403 = false;
        state.isError = true;
        state.isSuccess = false;
    },

    hasError403(state) {
        state.isLoading = false;
        state.isError403 = true;
        state.isError = false;
        state.isSuccess = false;
    },

    isSuccess(state) {
        state.isLoading = false;
        state.isError403 = false;
        state.isError = false;
        state.isSuccess = true;
    }, 
    
    resetStatus(state) {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = false;
    },     
  }
});

// Reducer
export default slice.reducer;

// Actions
export const { dataSuccess, hasError, dataSliceUpdate, startLoading, addBreadcrumbs, resetBreadcrumbs, removeBreadcrumbs, dataOneStepback, resetStatus, addPathMove, resetPathMove } = slice.actions;

// ----------------------------------------------------------------------

export function filemanagerBase() {
    return async (dispatch) => {
        dispatch(slice.actions.startLoading());
        const config = {
            headers: {
                "x-token": localStorage.getItem('token'),
                "Content-Type": "application/json"
            }
        };
        try {            
            const response = await axios.get(process.env.REACT_APP_API_URL+'/filemanager/get/all', config);
            const { data } = response;

            dispatch(slice.actions.dataSuccess(data));
            dispatch(slice.actions.dataSliceUpdate(data));
            dispatch(slice.actions.resetBreadcrumbs());
        } catch(err) {
            dispatch(slice.actions.hasError());
        }
    };
}

export function filemanagerUpdateTree(breadcrumbs) {
    return async (dispatch) => {
        const config = {
            headers: {
                "x-token": localStorage.getItem('token'),
                "Content-Type": "application/json"
            }
        };
        try {
            const response = await axios.get(process.env.REACT_APP_API_URL+'/filemanager/get/all', config);
            const { data } = response;

            // Update the global store
            dispatch(slice.actions.dataSuccess(data));

            // Copy the files to manipulate it in the loop            
            let file = data; 
            const crumbs = breadcrumbs;
    
            for (let i = 0; i < crumbs.length; i++){
                // get the position of the value in the array
                const posval = crumbs[i].posval; 
                // get in to the value and extract the other key
                const val = file[posval].other;
                // push the falue in the file variable to finalize it
                file = val;
            }
            // Update the store with the final array            
            dispatch(slice.actions.dataSliceUpdate(file));
        } catch(err) {
            dispatch(slice.actions.hasError());
        }
    };
}

export function filemanagerCreateFolder(path, breadcrumbs) {
    return async (dispatch) => {
        dispatch(slice.actions.startLoading());
        const folder = {
            folderName: path
        };
        const config = {
            headers: {
                "x-token": localStorage.getItem('token'),
                "Content-Type": "application/json"
            }
        };
        try {            
            const response = await axios.put(process.env.REACT_APP_API_URL+'/filemanager/folder/create', folder, config);
            const { data } = response;

            dispatch(filemanagerUpdateTree(breadcrumbs));
            dispatch(slice.actions.isSuccess());         
        } catch(err) {
            dispatch(slice.actions.hasError());
        }
    };
}

export function filemanagerEditFolder({filename, path, breadcrumbs}) {
    return async (dispatch) => {
        dispatch(slice.actions.startLoading());
        const folder = {
            folderPath: path,
            folderName: filename
        };
        const config = {
            headers: {
                "x-token": localStorage.getItem('token'),
                "Content-Type": "application/json"
            }
        };
        try {            
            const response = await axios.patch(process.env.REACT_APP_API_URL+'/filemanager/folder/edit', folder, config);
            const { data } = response;

            dispatch(filemanagerUpdateTree(breadcrumbs));  
            dispatch(slice.actions.isSuccess());          
        } catch(err) {
            dispatch(slice.actions.hasError());
        }
    };
}

export function filemanagerEditFile({id, row, breadcrumbs}) {
    return async (dispatch) => {
        dispatch(slice.actions.startLoading());
        const file = row;
        const config = {
            headers: {
                "x-token": localStorage.getItem('token'),
                "Content-Type": "application/json"
            }
        };
        try {            
            const response = await axios.patch(process.env.REACT_APP_API_URL+'/filemanager/file/edit/'+id, file, config);
            const { data } = response;

            dispatch(filemanagerUpdateTree(breadcrumbs));
            dispatch(slice.actions.isSuccess());
        } catch(err) {
            if(err.response.status === 403){
                dispatch(slice.actions.hasError403());
            } else {
                dispatch(slice.actions.hasError());
            }
        }
    };
}

export function filemanagerMoveFile({arrayids, pathdestination, breadcrumbs}) {
    return async (dispatch) => {
        dispatch(slice.actions.startLoading());
        const file = {
            movefiles: arrayids,
            destinationfolder: pathdestination
        };
        const config = {
            headers: {
                "x-token": localStorage.getItem('token'),
                "Content-Type": "application/json"
            }
        };
        try {            
            const response = await axios.patch(process.env.REACT_APP_API_URL+'/filemanager/files/move', file, config);
            const { data } = response;

            dispatch(filemanagerUpdateTree(breadcrumbs));
            dispatch(slice.actions.isSuccess());
        } catch(err) {
            dispatch(slice.actions.hasError());
        }
    };
}

export function filemanagerMoveFolder({pathorigin, pathdestination, breadcrumbs}) {
    return async (dispatch) => {
        dispatch(slice.actions.startLoading());
        const folder = {
            folderPath: pathdestination,
            folderName: pathorigin[0]
        };
        const config = {
            headers: {
                "x-token": localStorage.getItem('token'),
                "Content-Type": "application/json"
            }
        };
        try {            
            const response = await axios.patch(process.env.REACT_APP_API_URL+'/filemanager/folder/move', folder, config);
            const { data } = response;

            dispatch(filemanagerUpdateTree(breadcrumbs));
            dispatch(slice.actions.isSuccess());
        } catch(err) {
            dispatch(slice.actions.hasError());
        }
    };
}

export function filemanagerDelete({array, breadcrumbs}) {
    return async (dispatch) => {
        dispatch(slice.actions.startLoading());
        const file = {
            ids: array
        };
        const config = {
            headers: {
                "x-token": localStorage.getItem('token'),
                "Content-Type": "application/json"
            }
        };
        try {            
            const response = await axios.patch(process.env.REACT_APP_API_URL+'/filemanager/delete', file, config);
            const { data } = response;

            dispatch(filemanagerUpdateTree(breadcrumbs));
            dispatch(slice.actions.isSuccess());
        } catch(err) {
            dispatch(slice.actions.hasError());
        }
    };
}