import React, { lazy } from 'react';
import { Redirect } from 'react-router-dom';
import HomeLayout from 'src/layouts/HomeLayout';

// ----------------------------------------------------------------------

const HomeRoutes = {
  layout: HomeLayout,
  routes: [
    {
      exact: true,
      path: '/',
      component: () => <Redirect to="/auth/login" />
    },
    {
      exact: true,
      path: '/login',
      component: () => <Redirect to="/auth/login" />
    }
  ]
};

export default HomeRoutes;
