import { call, put } from "redux-saga/effects";
import requestGetFormfield from "../request/getformfield";
import errors from "src/redux/saga/errors";
import { openField } from "../../slices/formfield";

export function* handleGetFormfield(action) {
    try {
        const { payload } = action;
        const response = yield call(requestGetFormfield, payload);
        const { data } = response;
        yield put(openField({openFieldSetup: payload.openFieldSetup, field: data}));
        // yield put(getSingle({title: payload.model, id: payload.id}));
    } catch (error){ 
        console.log(error);
        errors(error);
    }
}