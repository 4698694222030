import axios from "axios";

export default function requestDeleteFormfieldoptions(endpoint) {

    const { id, fieldid } = endpoint;

    return axios.delete(process.env.REACT_APP_API_URL + '/form/field/options/' + id, {
        headers: {
            "x-token": localStorage.getItem('token'),
            "Content-Type": "application/json"
        },
        data: {
            fieldid: fieldid
        }
    });

}