import axios from "axios";

export default function handleCreateFormfieldOption(endpoint){

    const { id } = endpoint;

    const config = {
        headers: {
            "x-token": localStorage.getItem('token'),
            "Content-Type": "application/json"
        }        
    };
    const locale = localStorage.getItem('i18nextLng');

    const data = {
        id: id
    };


    ///{lang}/{entity}/{id-entity}/slider/{name-slider}/{id-slide}
    
    return axios.post(process.env.REACT_APP_API_URL+'/form/field/options/create', data, config)

}