import { call, put } from "redux-saga/effects";
import { updateCurrentSingle, setError } from "../../slices/single";
import requestCreateSingle from "../request/createsingle";
import history from "src/routes/history";
import errors from "src/redux/saga/errors";
import { getSingle, purgeSingleState } from 'src/redux/slices/single';

export function* handleCreateSingle(action) {
    try {
        const { payload } = action;
        const { pagename } = payload;
        const response = yield call(requestCreateSingle, payload);
        const { data } = response;        

        yield put(getSingle({title: payload.pagename, id: data.id}));

        history.push('/app/post/'+pagename+'/single/'+data.id);

    } catch (error){
        // yield put(setError());
        // console.log(error);
        errors(error);
    }
}