import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';

export const formfield = createSlice({
  name: 'formfield',
  initialState: {
    openFieldSetup: false,
    openOptionsSetup: false,
    create: true,
    typology: [],
    thefield: null,
    options: [],
    type: [],
    typelist: [],
    entity: null,
    entityid: null,
    name: null,
    id: null,
    index: null
  },
  reducers: {
    addField() {},
    saveField() {},
    getField() {},
    setField() {},
    openField(state, action) {
      const { openFieldSetup, field } = action.payload;
      state.thefield = field.items;
      state.id = field.id;
      state.create = field.create;
      state.openFieldSetup = openFieldSetup;
    },
    closeField(state, action) {
      state.thefield = null;
      state.openFieldSetup = false;
      state.entity = null;
      state.entityid = null;
      state.name = null;
      state.id = null;
      state.index = null
    },
    addFieldOptions() {},
    getOptions() {},
    deleteOption() {},
    openOptions(state, action) {
      const { openOptionsSetup, options, type, typelist, id } = action.payload;
      state.options = options;
      state.type = type;
      state.typelist = typelist;
      state.id = id;
      state.openOptionsSetup = openOptionsSetup;
    },
    feedOption(state, action) {
      const { data } = action.payload;
      state.options = data;
    },
    feedTypology(state, action) {
      const { data } = action.payload;
      state.typology = data;
    },
    closeOptions(state, action) {
      state.options = null;
      state.openOptionsSetup = false;
    },
    resetOptions(state, action) {
      state.typology = []
    }
  }
})

// Reducer
export default formfield.reducer;

// Actions
export const { 
  saveField, 
  openField, 
  getField, 
  setField, 
  addField, 
  closeField, 
  editField, 
  openFieldSetup, 
  resetOptions,
  getOptions,
  openOptions,
  closeOptions,
  addFieldOptions,
  feedOption,
  deleteOption
} = formfield.actions;

// ----------------------------------------------------------------------

export function typologyRequest(value) {
  return async (dispatch) => {
      const config = {
          headers: {
              "x-token": localStorage.getItem('token'),
              "Content-Type": "application/json"
          }        
      };
      const el = {
        element: value
      }      
      try {
          const response = await axios.post(process.env.REACT_APP_API_URL+'/form/field/typology', el, config);
          const { data } = response;

          dispatch(formfield.actions.feedTypology({data}));
      } catch(err) {
          // dispatch(formfield.actions.hasError());
          console.log(err);
      }
  };
}