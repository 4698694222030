import { call, put } from "redux-saga/effects";
import requestUpdateFieldrepeater from "../request/updatefieldrepeater";
import errors from "src/redux/saga/errors";
import { getSingle } from 'src/redux/slices/single';

export function* handleUpdateFieldrepeater(action) {
    try {
        const { payload } = action;
        const response = yield call(requestUpdateFieldrepeater, payload);
        const { data } = response;
        debugger;
        if(data.model != 'visualblock' && data.model != 'productitem'){
            yield put(getSingle({title: payload.model, id: payload.postid}));
        } else {
            window.location.reload();
        }
    } catch (error){ 
        console.log(error);
        errors(error);
    }
}