import { createSlice } from '@reduxjs/toolkit';

export const propertySlice = createSlice({
  name: 'property',
  initialState: {
    new: 0,
    open: false,
    id: 0,
    type: null,
    create: null,
    pagename: null,
    pageid: null,
    value: null
  },
  reducers: {
    getPropertyvalue() {},    
    setPropertyvalue(state, action) {
      const property = action.payload;
      state.create = property.create;
      state.value = property;
    },
    updatePropertyvalue() {},
    setIdValue(state, action){
      const { id } = action.payload;
      state.id = id;
    },
    createPropertyvalue() {},
    addPostlistvalue(state, action) {
      state.new += 1;
      state.open = false;
      return state;
    },
    openPanel(state, action) {
      const { open, pagename, type, pageid } = action.payload;   
      state.open = open;
      state.pagename = pagename;
      state.pageid = pageid;
      state.type = type;
    }, 
    purgePropertyValue(state) {
      state.value = null;
    },  
    purgePostValueState(state) {      
      state.new = 0;
      state.open = false;
      state.id = 0;
      state.type = null;
      state.pagename = null;
      state.pageid = null;
      state.value = null;
      return state;
    },     
    clearState: (state, action) => {
      state.id = 0;
      return state;
    }
  }
})

export const { 
  getPropertyvalue, 
  updatePropertyvalue, 
  createPropertyvalue,
  setPropertyvalue, 
  openPanel, 
  createPostlistvalue, 
  addPostlistvalue, 
  clearState, 
  setIdValue, 
  purgePostValueState, 
  purgePropertyValue } = propertySlice.actions;

export default propertySlice.reducer;