import axios from "axios";

export default function requestgetSingle(endpoint){

    const { id, title, copy, lang } = endpoint;

    let locale = lang;
    if(!locale){
        locale = localStorage.getItem('i18nextLng');
    }

    const config = {
        params: {
            'copytranslate': copy
        },
        headers: {
            "x-token": localStorage.getItem('token'),
            "Content-Type": "application/json"
        }
    };

    return axios.get(process.env.REACT_APP_API_URL+"/"+locale+"/"+title+"/"+id, config);
}