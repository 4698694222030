import { call, put } from "redux-saga/effects";
import requestDeleteFormfieldoptions from "../request/deleteoptions";
import errors from "src/redux/saga/errors";
import { feedOption } from "../../slices/formfield";

export function* handleDeleteFormfieldOptions(action) {
    try {
        const { payload } = action;
        const response = yield call(requestDeleteFormfieldoptions, payload);
        const { data } = response;
        yield put(feedOption({data}));
    } catch (error){ 
        console.log(error);
        errors(error);
    }
}