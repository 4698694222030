import axios from "axios";

export default function requestCopyColumns(endpoint){

    const { id, model, data } = endpoint;

    const config = {
        headers: {
            "x-token": localStorage.getItem('token'),
            "Content-Type": "application/json"
        }        
    };
    const locale = localStorage.getItem('i18nextLng');
    
    return axios.post(process.env.REACT_APP_API_URL+'/'+locale+'/thetable/'+model+'/'+id, data, config)

}