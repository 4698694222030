import { call, put } from "redux-saga/effects";
import { setItem } from "../../slices/item";
import requestgetItem from "../request/getitem";
import errors from "src/redux/saga/errors";

export function* handleGetItem(action) {
    try {
        const { payload } = action;   
        const response = yield call(requestgetItem, payload);
        const { data } = response;
        yield put(setItem(data));
    } catch (error){ 
        console.log(error);
        errors(error);
    }
}