import { call, put } from "redux-saga/effects";
import { setProductitem } from "../../slices/product";
import requestgetProductitem from "../request/getproductitem";
import errors from "src/redux/saga/errors";

export function* handleGetProductitem(action) {
    try {
        const { payload } = action;   
        const response = yield call(requestgetProductitem, payload);
        const { data } = response;
        yield put(setProductitem(data));
    } catch (error){ 
        console.log(error);
        errors(error);
    }
}