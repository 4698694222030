import { call, put } from "redux-saga/effects";
import requestSaveBlock from "../request/saveblock";
import errors from "src/redux/saga/errors";
import { getSingle, purgeSingleState } from 'src/redux/slices/single';
import { successSaveBlock } from 'src/redux/slices/visual';

export function* handleSaveBlock(action) {
    try {
        const { payload } = action;
        const response = yield call(requestSaveBlock, payload);
        const { data } = response;
        yield put(successSaveBlock());
        yield put(getSingle({title: payload.entity, id: payload.entityid}));
    } catch (error){ 
        console.log(error);
        errors(error);
    }
}