import { call, put } from "redux-saga/effects";
import { setPropertyvalue } from "../../slices/property";
import requestCreatePropertyvalue from "../request/createpropertyvalue";
import errors from "src/redux/saga/errors";

export function* handleCreatePropertyvalue(action) {
    try {
        const { payload } = action;
        const response = yield call(requestCreatePropertyvalue, payload);
        const { data } = response;
        yield put(setPropertyvalue(data));
    } catch (error){ 
        console.log(error);
        errors(error);
    }
}