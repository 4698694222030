import { call, put } from "redux-saga/effects";
import requestCreateProductitem from "../request/createproductitem";
import errors from "src/redux/saga/errors";
import history from "src/routes/history";

export function* handleCreateProductitem(action) {
    try {
        const { payload } = action;
        const response = yield call(requestCreateProductitem, payload);
        const { data } = response;
        history.push('/app/post/'+data.model+'/single/'+data.modelid+'/item/'+data.id);
    } catch (error){ 
        console.log(error);
        errors(error);
    }
}