import { call, put } from "redux-saga/effects";
import { purgeSlide } from "../../slices/slider";
import requestUpdateSlider from "../request/updateslider";
import errors from "src/redux/saga/errors";
import { getSingle } from 'src/redux/slices/single';

export function* handleUpdateSlider(action) {
    try {
        const { payload } = action;
        const response = yield call(requestUpdateSlider, payload);
        const { data } = response;
        if(data.model != 'visualblock' && data.model != 'productitem'){
            yield put(purgeSlide());
            yield put(getSingle({title: payload.model, id: payload.postid}));
        } else {
            window.location.reload();
        }
    } catch (error){ 
        console.log(error);
        errors(error);
    }
}