import axios from "axios";

export default function requestgetProductitem(endpoint){

    const { model, modelid, id } = endpoint;

    const lang = localStorage.getItem('i18nextLng');

    const config = {
        headers: {
            "x-token": localStorage.getItem('token'),
            "Content-Type": "application/json"
        }        
    };

    return axios.get(process.env.REACT_APP_API_URL + '/' + lang + '/' + model + '/' + modelid + '/item/' + id, config);

}