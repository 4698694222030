import { createSlice } from '@reduxjs/toolkit';

export const slider = createSlice({
  name: 'propertyval',
  initialState: {
    openslidevisual: false,
    openslide: false,
    slide: null,
    entity: null,
    entityid: null,
    name: null,
    id: null
  },
  reducers: {
    createSlide() {},
    getSlide() {},
    updateSlide() {},
    setIdSlide(state, action) {
      const id = action.payload;
      state.id = id;
    },
    setSlide(state, action) {      
      const slide = action.payload;
      state.slide = slide;
      state.id = slide[0].value;
    },      
    openSlide(state, action) {
      const { openslide } = action.payload;
      state.openslide = openslide;
    },
    openSlideVisualBlock(state, action) {
      const { openslidevisual } = action.payload;
      state.openslidevisual = openslidevisual;
    },
    purgeSlide(state, action) {
      state.openslide = false;
      state.openslidevisual = false;
      state.entity = null;
      state.slide = null;
      state.entityid = null;
      state.name = null;
      state.id = null;      
    }
  }
})

export const { openSlide, getSlide, setSlide, createSlide, purgeSlide, setIdSlide, updateSlide, openSlideVisualBlock } = slider.actions;

export default slider.reducer;