import axios from "axios";

export default function requestCreateSingle(endpoint){

    const { pagename, data } = endpoint;

    let lang = localStorage.getItem('i18nextLng');

    const config = {
        headers: {
            "x-token": localStorage.getItem('token'),
            "Content-Type": "application/json"
        }                
    };

    const obj = data ? data : {};

    return axios.post(process.env.REACT_APP_API_URL+"/"+lang+"/"+pagename+"/create", obj, config);
}