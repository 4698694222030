import clsx from 'clsx';
import React, { useRef, useState, useEffect } from 'react';
import PopoverMenu from 'src/components/PopoverMenu';
import { makeStyles } from '@material-ui/core/styles';
import { Box, MenuItem, ListItemIcon, ListItemText } from '@material-ui/core';
import { MIconButton } from 'src/theme';
import { useTranslation } from 'react-i18next';
import axios from "axios";
import history from "src/routes/history";
import { useDispatch, useSelector } from 'react-redux';
import { defaultlang } from 'src/redux/slices/langs';
import { useLocation } from 'react-router-dom';
import { useHistory } from "react-router-dom";
import handlerErrors from "src/utils/errors";

// ----------------------------------------------------------------------

const useStyles = makeStyles((theme) => ({
  menuItem: {
    padding: theme.spacing(1, 2.5)
  },
  btnLang: {
    padding: 0,
    width: 44,
    height: 44
  },
  isSelected: {
    backgroundColor: theme.palette.background.selected
  }
}));

// ----------------------------------------------------------------------

function Languages() {
  const classes = useStyles();
  const anchorRef = useRef(null);
  const [isOpen, setOpen] = useState(false);
  const [list, setList] = useState(null);
  const [langs, setLangs] = useState({code_txt_general: "en", displayname_txt_general: "English"});  

  const { lang } = useSelector((state) => state.lang);
  const { post, pagename, id, title, url, params } = useSelector((state) => state.single);  
  // console.log('lang', lang);

  let location = useLocation();
  const arrayLocation = location.pathname.split('/');
  const folder = arrayLocation[arrayLocation.length-3];
  const single = arrayLocation[arrayLocation.length-2];  
  const idpage = arrayLocation[arrayLocation.length-1];  

  const { i18n } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();

  const getlangs = async () => {
    const data = {
      "searchterm": "",
      "pagesize": 10000
    };

    const config = {
      headers: {
        "x-token": localStorage.getItem('token'),
        "Content-Type": "application/json"
      }
    };    
    try {
      const i18nextLng = localStorage.getItem('i18nextLng');
      const response = await axios.post(process.env.REACT_APP_API_URL+'/'+i18nextLng+'/language', data, config);  
      const resData = await response;
      if (resData.status === 200) {        
        setList(clearList(resData.data.items));
        getDefault(clearList(resData.data.items));
      } else {
        handlerErrors(resData);  
      } 
    } catch(error) {
      handlerErrors(error);
    }
  }   

  const clearList = (data) => {
    const list = data.map(e => {
      return e.filter(i => {
        if(i.id == 'displayname_txt_general' || i.id == 'code_txt_general' || i.id == 'default_is_general' || i.id == 'enabled_is_general'){
          return i;
        }
      });
    }).map(e => {
      return e.reduce((o, key) => ({...o, [key.id]: key.value}), {});
    });    
    setLangs(currentlang(list));
    return list;
  }

  const getDefault = (data) => {
    const langs = data;
    for(let i = 0; i < langs.length; i++){
      if(langs[i].default_is_general == 'true'){        
        dispatch(defaultlang(langs[i].code_txt_general));
        localStorage.setItem('i18nextLngDefault', langs[i].code_txt_general);
      }
    }
  }

  const currentlang = (data) => {
    const lang = data.filter(e => {
      if(localStorage.getItem('i18nextLng') == e.code_txt_general){
        return e;
      }      
    });
    // console.log('currentlang', lang);
    return lang[0];
  };  
  
  const handleLang = (option) => {
    setLangs(option);
    setOpen(false);
    i18n.changeLanguage(option.code_txt_general);

    //** Check if the page is single */    
    if(single !== 'single'){
      window.location.reload();
    } else {
      history.push('/app/'+folder+'/'+single+'/'+option.id);
      window.location.reload();
    }
  }

  /**
   * if the app is in the single page the logic must be different so we use the 
   * information in the post array is the correct one case contains the information abount other languages
   */

  const singlepagelang = () => {
    const items = post ? post : [];
    const langs = items.filter(e => {
      if(e.name === 'lang_langs_general'){
        return e;
      }
    });
    const obj = langs.length > 0 ? langs[0].value : null;
    const langArray = list ? list.map(e => {
      let el = Object.assign({}, e);
      const lang = obj ? obj.map(i => {
        if(i.code == e.code_txt_general){       
          el['id'] = i.id;
        }        
      }) : null;
      return el
    }) : null; 
    return langArray;
  }  
  
  useEffect(() => {             
    getlangs();    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lang]);

  useEffect(() => {
    if(single === 'single'){
      setList(singlepagelang());
    }
  },[post]);

  if(list){
    return (
      <>
      {langs ?
        <MIconButton
          ref={anchorRef}
          onClick={() => setOpen(true)}
          className={clsx(classes.btnLang, { [classes.isSelected]: isOpen })}>
            <img src={'/static/icons/ic_flag_'+langs.code_txt_general+'.svg'} />
        </MIconButton>
      : null}
  
        <PopoverMenu
          width={200}
          open={isOpen}
          onClose={() => setOpen(false)}
          anchorEl={anchorRef.current}
        >
          <Box sx={{ py: 1 }}>
            {list.map((option, index) => {
              if(option.enabled_is_general == 'true'){
                return (<MenuItem
                  key={index}
                  selected={option.code_txt_general === lang.code_txt_general}
                  onClick={() => {handleLang(option); }}
                  className={classes.menuItem}
                >
                  <ListItemIcon>
                    <Box component="img" alt={option.displayname_txt_general} src={'/static/icons/ic_flag_'+option.code_txt_general+'.svg'} />
                  </ListItemIcon>
    
                  <ListItemText primaryTypographyProps={{ variant: 'body2' }}>
                    {option.displayname_txt_general}
                  </ListItemText>
                </MenuItem>)
              } else {
                return null
              }
            })}
          </Box>
        </PopoverMenu>
      </>
    );    
  } else {
    return null
  }
}

export default Languages;
