import { PATH_AUTH } from './paths';
import React, { lazy } from 'react';
import { Redirect } from 'react-router-dom';
import AuthLayout from 'src/layouts/AuthLayout';

// ----------------------------------------------------------------------

const AuthRoutes = {
  path: PATH_AUTH.root,
  layout: AuthLayout,
  routes: [
    {
      exact: true,
      path: PATH_AUTH.login,
      component: lazy(() => import('src/views/Login'))
    },
    {
      component: () => <Redirect to="/404" />
    }
  ]
};

export default AuthRoutes;
