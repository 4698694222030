import axios from "axios";

export default function requestCreateCsvfield(endpoint){

    const { id } = endpoint;

    const config = {
        headers: {
            "x-token": localStorage.getItem('token'),
            "Content-Type": "application/json"
        }        
    };

    const lang = localStorage.getItem('i18nextLng');

    return axios.post(process.env.REACT_APP_API_URL +'/'+lang+ '/form/csv/field/' + id, {}, config);;

}