import { createSlice } from '@reduxjs/toolkit';

export const listingSlice = createSlice({
  name: 'listing',
  initialState: null,
  reducers: {
    getListing() {},
    setListing(state, action) {      
      const listingData = action.payload;
      return {...state, ...listingData}
    },
    purgeListingState(state){
      return null;
    }    
  }
})

export const { getListing, setListing, purgeListingState } = listingSlice.actions;

export default listingSlice.reducer;