const path = (name) => `/static/icons/navbar/${name}.svg`;

export default function IconMenu(menu) {

    const icon = menu.map((el) => {

        el.items.map((e) => {
            e.icon = path('ic_'+e.icon);
        });

        return el

    });

    return icon;
}