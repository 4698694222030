import { createSlice } from '@reduxjs/toolkit';

export const langSlice = createSlice({
  name: 'lang',
  initialState: {
    lang: '',
    defaultlang: ''
  },
  reducers: {
    getlang() {},
    setlang(state, action) {      
      const langData = action.payload;
      state.lang = langData;
      // return state;
    },
    defaultlang(state, action){
      const langData = action.payload;
      state.defaultlang = langData;
    },
    purgelangState(state){
      state.lang = '';
      state.default = '';
      // return state;
    }    
  }
})

export const { getlang, setlang, defaultlang, purgelangState } = langSlice.actions;

export default langSlice.reducer;