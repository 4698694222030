import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist';
import { rootPersistConfig, rootReducer } from './rootReducer';
import createSagaMiddleware from "redux-saga";
import { watcherSaga } from "./saga/rootSaga";

// ----------------------------------------------------------------------

const sagaMiddleware = createSagaMiddleware();

const store = configureStore({
  reducer: persistReducer(rootPersistConfig, rootReducer),
  middleware: [...getDefaultMiddleware({
    serializableCheck: false,
    immutableCheck: true,
    thunk: true
  }), 
  sagaMiddleware ]
});

sagaMiddleware.run(watcherSaga);

const persistor = persistStore(store);

export { store, persistor };
