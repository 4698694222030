import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';

// ----------------------------------------------------------------------

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%'
  },
  content: {
    height: '100%'
  }
}));

// ----------------------------------------------------------------------

AuthLayout.propTypes = {
  children: PropTypes.node
};

function AuthLayout({ children }) {

  const classes = useStyles();
  const { token } = useSelector((state) => state.login);

  if(!token) {
    return (
      <div className={classes.root}>
        <div className={classes.content}>{children}</div>
      </div>
    );
  } else {
    return (
      <Redirect to={{ pathname: '/app' }} />
    );
  }      
}

export default AuthLayout;
