import axios from "axios";

export default function requestgetPropertyvalue(endpoint){

    const { id } = endpoint;

    const lang = localStorage.getItem('i18nextLng');

    const config = {
        headers: {
            "x-token": localStorage.getItem('token'),
            "Content-Type": "application/json"
        }        
    };

    return axios.get(process.env.REACT_APP_API_URL+'/'+lang+'/property/value/'+id, config);

}