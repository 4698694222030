import { call, put } from "redux-saga/effects";
import { openPanel } from "../../slices/property";
import { getSingle } from "../../slices/single";
import requestputPropertyvalue from "../request/updatepropertyvalue";
import errors from "src/redux/saga/errors";

export function* handleUpdatePropertyvalue(action) {
    try {
        const { payload } = action;
        const response = yield call(requestputPropertyvalue, payload);
        const { data } = response;
        yield put(openPanel({open: false}));
        yield put(getSingle({title: payload.model, id: payload.postid}));
    } catch (error){ 
        console.log(error);
        errors(error);
    }
}