import { call, put } from "redux-saga/effects";
import requestUpdateCsvfield from "../request/updatecsvfield";
import errors from "src/redux/saga/errors";
import { getSingle } from 'src/redux/slices/single';

export function* handleUpdateCsvfield(action) {
    try {
        const { payload } = action;
        const { pagename } = payload;
        const response = yield call(requestUpdateCsvfield, payload);
        const { data } = response;        

        yield put(getSingle({title: 'form', id: payload.formid}));

    } catch (error){
        // yield put(setError());
        // console.log(error);
        errors(error);
    }
}