import { call, put } from "redux-saga/effects";
import { getFieldrepeater, setIdFieldrepeater } from "../../slices/fieldrepeater";
import requestCreateFieldrepeater from "../request/createfieldrepeater";
import errors from "src/redux/saga/errors";
import { getSingle } from 'src/redux/slices/single';

export function* handleCreateFieldrepeater(action) {
    try {
        const { payload } = action;
        const response = yield call(requestCreateFieldrepeater, payload);
        const { data } = response;
        yield put(setIdFieldrepeater(data.id));
        yield put(getFieldrepeater({id: data.id}));
        // yield put(getSingle({title: payload.model, id: payload.id}));
    } catch (error){ 
        console.log(error);
        errors(error);
    }
}