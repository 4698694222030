import { createSlice } from '@reduxjs/toolkit';

export const propertySlice = createSlice({
  name: 'item',
  initialState: {
    new: 0,
    open: false,
    id: 0,
    type: null,
    pagename: null,
    pageid: null,
    valueitem: null,
    isSuccess: null,
    isError: null
  },
  reducers: {
    getItem() {},    
    setItem(state, action) {
      const property = action.payload;
      state.valueitem = property;
      state.isSuccess = true;
    },
    updateItem() {},
    setIdValue(state, action){
      const { id } = action.payload;
      state.id = id;
    },
    setIs(state, action) {
      state.isError = false;
      state.isSuccess = false;
    },    
    clearValueitem(state) {
      state.valueitem = null;
    },
    createItem() {},
    addPostlistvalue(state, action) {
      state.new += 1;
      state.open = false;
      return state;
    },
    openPanelProduct(state, action) {
      const { open, pagename, type, pageid } = action.payload;   
      state.open = open;
      state.pagename = pagename;
      state.pageid = pageid;
      state.type = type;
    }, 
    purgeItem(state) {
      state.valueitem = null;
    },  
    purgePostValueState(state) {      
      state.new = 0;
      state.open = false;
      state.id = 0;
      state.type = null;
      state.pagename = null;
      state.pageid = null;
      state.valueitem = null;
      return state;
    },     
    clearState: (state, action) => {
      state.id = 0;
      return state;
    }
  }
})

export const { 
  getItem, 
  clearValueitem,
  updateItem, 
  createItem,
  setItem, 
  openPanelProduct, 
  createPostlistvalue, 
  addPostlistvalue, 
  clearState, 
  setIdValue,
  setIs,
  purgePostValueState, 
  purgeItem } = propertySlice.actions;

export default propertySlice.reducer;